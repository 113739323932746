import Vue from 'vue';

interface AgreementData {
	agreementTermsContent: string;
	agreementTermsType: string;
	agreementTermsUrl: string;
	apartmentName?: string;
	householdName?: string;
	idx?: string;
	name?: string;
	phone?: string;
	roomName?: string;
}

export default Vue.extend({
	data() {
		return {
			model: "호반",
			dialog: false,
			data: {
				agreementTermsContent: '',
				agreementTermsType: '',
				agreementTermsUrl: '',
				apartmentName: '',
				householdName: '',
				idx: '',
				name: '',
				phone: '',
				roomName: '',
			} as AgreementData,
			btnAgree: false
		};
	},
	mounted() {
		this.$vuetify.theme.dark = false;

		if (this.$route.query.referrer === undefined) {
			this.$router.replace('/private/consentError');
			return false;
		}

		if (typeof this.$route.query.referrer === 'string' && this.$route.query.referrer.toLowerCase() === 'kakao') {
			this.btnAgree = true;
			this.HANDLE_AGREE();
			return true;
		}

		this.$store
			.dispatch('api/agreement_detail', {
				agreement_idx: this.$route.params.constructionIdx
			})
			.then((response) => {
				if (response.status === 200) {
					response.data.name = (this._ as any).decrypt_bidirectional(response.data.name);
					response.data.phone = (this._ as any).decrypt_bidirectional(response.data.phone);
					this.data = response.data;
					this.btnAgree = true;
					return true;
				}
			})
			.catch((error) => {
				if (error.error === 'ERR_AGREEMENT_ALREADY_COMPLETED') {
					this.$router.replace('/private/consentEnd');
					return false;
				}
				this.$router.replace('/private/consentError');
			});
	},
	beforeDestroy() {
		this.$vuetify.theme.dark = true;
	},
	methods: {
		HANDLE_AGREE() {
			if (this.btnAgree) {
				this.$store
					.dispatch('api/agreement_create', {
						agreement_idx: this.$route.params.constructionIdx
					})
					.then(() => {
						this.$router.replace('/private/consentComplete');
					})
					.catch((error) => {
						if (error.error === 'ERR_AGREEMENT_ALREADY_COMPLETED') {
							this.$router.replace('/private/consentEnd');
							return false;
						}
						this.$router.replace('/private/consentError');
					});
			}
		},
		HANDLE_OPEN() {

			if(this.data.agreementTermsType === 'URL')
			{
				window.open(this.data.agreementTermsUrl);
				return true;
			}

			const agreementContent =
				this.data.agreementTermsType === 'URL'
					? `<iframe src="${this.data.agreementTermsUrl}" width="100%" height="400px" frameborder="0" allowfullscreen></iframe>`
					: `<div style="white-space: pre-wrap;">${this.data.agreementTermsContent}</div>`;

			const newWindow = window.open('', '_blank', 'width=600,height=800');
			if (newWindow) {
				newWindow.document.write(`
					<html>
						<head>
							<title>개인정보 활용 동의</title>
						</head>
						<body style="font-family: Pretendard, sans-serif; padding: 20px;">
							<h1 style="font-size: 18px; font-weight: bold;">개인정보 활용 동의</h1>
							<h2 style="font-size: 14px; font-weight: bold; margin-top: 12px;">개인정보의 수집 및 이용 목적</h2>
							<div style="font-size: 12px; line-height: 28px; margin-top: 12px;">
								${agreementContent}
							</div>
							<button onclick="window.close()" style="width: 100%; border:0; color: #fff; background: #000; border-radius: 8px; margin-top: 20px; padding: 20px 20px; font-size: 16px;">닫기</button>
						</body>
					</html>
				`);
			} else {
				alert("새 창을 열 수 없습니다. 팝업 차단을 해제해주세요.");
			}
		}
	}
});
