import Vue from 'vue';

export default Vue.extend({
	data () {
		return {
			model: "호반",
			dialog: false,
			data: {},
			btnAgree: false
		}
	},
	mounted()
	{
		this.$vuetify.theme.dark = false;
	},
	beforeDestroy() {
		this.$vuetify.theme.dark = true;
	},
	methods:
	{
		HANDLE_AGREE()
		{
			window.close();
		}
	}
});
