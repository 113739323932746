






























import ViewModel from '@/views/kakaoTalk/consentComplete.ts';
export default ViewModel;
