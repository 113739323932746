import Vue from 'vue';

export default Vue.extend({
	data () {
		return {
			model: "호반",
			dialog: false,
			data: {},
			btnAgree: false
		}
	},
	mounted()
	{
		this.$vuetify.theme.dark = false;
		this.$store.dispatch('api/agreement_terms_detail', {
			agreement_idx: this.$route.params.constructionIdx
		}).then( (response) => {
			if(response.status === 200)
			{
				this.data = response.data;
				return true;
			}
		}).catch( (error) => {
			this.$toasted.show("유효하지 않거나 만료된 세션 입니다.", {position: 'top-center', duration: 10000});
		})
	},
	methods:
	{
		HANDLE_CLOSE()
		{
			window.close();
		}
	},
	beforeDestroy() {
		this.$vuetify.theme.dark = true;
	}
});
