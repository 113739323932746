

































































































































































import ViewModel from '@/views/kakaoTalk/consent.ts';
export default ViewModel;
