import { render, staticRenderFns } from "./consentEnd.vue?vue&type=template&id=1d6dacae&scoped=true"
import script from "./consentEnd.vue?vue&type=script&lang=ts"
export * from "./consentEnd.vue?vue&type=script&lang=ts"
import style0 from "./consentEnd.vue?vue&type=style&index=0&id=1d6dacae&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d6dacae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VIcon,VMain,VRow})
