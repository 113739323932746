















































import ViewModel from '@/views/kakaoTalk/terms.ts';
export default ViewModel;
